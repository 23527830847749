import './App.css';
import Header from './Header/Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import Gallery from './Gallery/Gallery';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import structure from './Structure/structure';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <div>
          <Header />
          <Switch>
            {/* TODO: aus liste automatisch generieren */}
            {structure.map(el => showGallery(el))}
          </Switch>
        </div>

      </BrowserRouter>
    </div>
  );
}

function showGallery(el) {
  if (el.children.length !== 0) {
    return el.children.map(child => printSingleGallery(`${el.name}/${child.name}`))
  }
  else {
    return printSingleGallery(el.name)
  }
}

function printSingleGallery(name) {
  console.log(name)
  return <Route key={name} path={`/${name}`}><Gallery category={name} /></Route>
}

export default App;
