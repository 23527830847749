import React, { Component } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import structure from "../Structure/structure";

export class Header extends Component {
  render() {
    return (
      <Navbar bg="light" expand="lg" style={{ marginBottom: 30 }}>
        <Navbar.Brand href="#home">panoramas & photos</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">{structure.map((el) => renderNav(el))}</Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

function renderNav(el) {
  if (el.children.length !== 0) {
    return (
      <NavDropdown
        title={el.displayName}
        id="basic-nav-dropdown"
        alignRight={true}
        key={el.name}
      >
        {el.children.map((child) => (
          <NavDropdown.Item key={child.name} as={Link} to={`/${el.name}/${child.name}`}>
            {child.displayName}
          </NavDropdown.Item>
        ))}
      </NavDropdown>
    );
  } else {
    return (
        <Nav.Link as={Link} to={`/${el.name}`} key={el.name}>
        {el.displayName}
      </Nav.Link>
    );
  }
}

export default Header;
