import React, { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import "photoswipe/dist/default-skin/default-skin.css";

import { Gallery as PsGallery, Item } from "react-photoswipe-gallery";
import { baseUrl } from "../environment";

import "./Gallery.css";
import "photoswipe/dist/photoswipe.css";
const axios = require("axios").default;

export default function Gallery(props) {
  const [photos, setPhotos] = useState([]);
  const [spinner, setSpinner] = useState(true);

  useEffect(() => {
    setSpinner(true);
    axios
      .get(`${baseUrl}${props.category}`)
      .then((response) => {
        setPhotos(response.data);
        setSpinner(false);
        console.log(response.data);
      })
      .catch(() => {});
  }, [props.category]);
  return (
    <Container className="containerBlack rounded">
      <Row className="justify-content-center no-gutters rowpadding">
        {!spinner ? (
          <PsGallery>
            {photos.map((photo) => (
              <Col
                key={photo.id}
                className="mb-0 pb-0"
                xs="6"
                sm="4"
                lg="3"
                xl="2"
              >
                <Item
                  original={photo.path}
                  thumbnail={photo.path.replace("/images/", "/thumbnails/")}
                  width={photo.width}
                  height={photo.height}
                >
                  {({ ref, open }) => (
                    <img
                      alt=""
                      ref={ref}
                      onClick={open}
                      className="imgcenter img-fluid mx-auto"
                      src={photo.path.replace("/images/", "/thumbnails/")}
                    />
                  )}
                </Item>
              </Col>
            ))}
          </PsGallery>
        ) : (
          <Spinner animation="border" />
        )}
      </Row>
    </Container>
  );
}
